/** 返回  */
export function goBack() {
  window.QinKey.goBack();
}

/** 页面跳转  */
export function goNextWebPage(data) {
  window.QinKey.goNextWebPage(JSON.stringify(data));
}

/** 去评论  */
export function goToComment(data) {
  window.QinKey.goToComment(JSON.stringify(data));
}

/** 在线客服  */
export function goToCustomerService(data) {
  window.QinKey.goToCustomerService(JSON.stringify(data));
}
