import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/welcome.vue';

const routes = [
  {
    path: '/welcome',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/tutorials',
    name: 'tutorials',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tutorials" */ '../views/tutorials.vue'),
  },
  {
    path: '/suggest',
    name: 'suggest',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "suggest" */ '../views/suggest.vue'),
  },
  {
    path: '/sendvip',
    name: 'sendvip',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sendvip" */ '../views/sendvip.vue'),
  },
  {
    path: '/pay',
    name: 'pay',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pay" */ '../views/pay.vue'),
  },
  {
    path: '/help',
    name: 'help',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "help" */ '../views/help.vue'),
  },
  {
    path: '/guide',
    name: 'guide',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "guide" */ '../views/guide.vue'),
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ '../views/aboutus.vue'),
  },
  {
    path: '/agreement/user',
    name: 'agreement/user',
    meta: {
      title: '用户协议',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "agreement-user" */ '../views/agreement/user.vue'
      ),
  },
  {
    path: '/agreement/privacy',
    name: 'agreement/privacy',
    meta: {
      title: '隐私协议',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "agreement-privacy" */ '../views/agreement/privacy.vue'
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = '亲Key键盘';
  }
});

export default router;
